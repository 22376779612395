<template>
  <div class="white grid justify-items-center px-0 py-10 text-left">
		<v-col cols="11" class="pa-0 mt-10 py-10">
			<v-row>
				<v-col cols="12" sm="6">
					<img src="@/assets/images/no_connection.png" width="100%"/>
				</v-col>
				<v-col cols="12" sm="6" class="text-black flex flex-wrap content-center">
					<v-col cols="12" class="text-3xl font-bold pl-0">{{bahasa? 'Tidak Dapat Tersambung ke Internet' : 'Cannot Connect to the Internet'}}</v-col>
					<v-btn class="btn-primary mt-8" :to="{name: 'home'}">
						{{bahasa ? 'coba lagi' : 'try again'}}
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>
<script>
export default {
	props: {
		bahasa: {
			type: Boolean,
			default: true
		},
	},
	computed: {
		isOnLine() {
			return navigator.onLine;
		},
	},
	mounted() {
		if(!this.isOnLine) {
			this.$router.push({name: 'no-connection'})
		} else {
			this.$router.push({name: 'home'})
		}
	}
}
</script>